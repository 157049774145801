
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        




























@import '@/styles/_colors.scss';

.progression__container {
  position: relative;
  width: 100%;
  height: 4px;
  margin-right: 1rem;
  background: $color-grey-100;
}

.progression__bar {
  height: 100%;
  background: var(--color-primary);
}
